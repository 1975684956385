/*CONTACT INFO*/
.ccm-contact-info-list-container {
  margin-bottom: 50px;
  display: inline-block; }
  .ccm-contact-info-list-container .ccm-list-contact-line {
    display: inline-block;
    width: 100%; }
    .ccm-contact-info-list-container .ccm-list-contact-line .contact-list-icon {
      display: inline-block;
      width: 25px;
      line-height: 25px; }
    .ccm-contact-info-list-container .ccm-list-contact-line .contact-list-info {
      display: inline-block;
      width: 80%; }
      .ccm-contact-info-list-container .ccm-list-contact-line .contact-list-info .ccm-page ul.ccm-icon-list-container {
        margin-bottom: 50px;
        list-style-type: none; }
      .ccm-contact-info-list-container .ccm-list-contact-line .contact-list-info .ccm-page ul.ccm-icon-list-container li i {
        width: 30px; }

/*ICON LIST*/
.ccm-page .ccm-icon-list-container {
  margin-bottom: 50px;
  display: inline-block; }
  .ccm-page .ccm-icon-list-container i {
    width: 25px; }

/*UL LIST*/
.ccm-page .ccm-ul-list-container {
  margin-bottom: 50px; }
  .ccm-page .ccm-ul-list-container ul {
    margin-bottom: 50px;
    list-style-type: disc;
    padding-left: 20px; }
    .ccm-page .ccm-ul-list-container ul li {
      padding-left: 5px; }

/*RATING LIST*/
.ccm-rating-list-container {
  position: relative; }
  .ccm-rating-list-container .ccm-list-rating {
    display: inline-block;
    width: 100%;
    margin-bottom: 50px; }
  .ccm-rating-list-container .ccm-list-rating-stars i {
    color: #ffae00; }

/*TEXT SLIDER*/
.ccm-text-slider-list-container {
  margin-bottom: 50px; }

/*SOCIAL ICONS*/
.ccm-social-icons-list-container {
  margin-bottom: 50px; }
  .ccm-social-icons-list-container a.ccm-list-social-icon {
    display: inline-block;
    width: 25px;
    height: 25px;
    line-height: 25px;
    margin-right: 10px;
    font-size: 20px;
    text-decoration: none; }
