@charset "UTF-8";
/* Imports */
@font-face {
  font-family: 'Russo One';
  src: url("fonts/RussoOne-Regular.woff2") format("woff2"), url("fonts/RussoOne-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/* Breakpoints */
/* Menu settings*/
/* Primary colors */
/* Additional colors */
/* Font settings */
/* PX TO EM */
* {
  box-sizing: border-box;
  outline: none; }

body,
html {
  margin: 0;
  padding: 0;
  min-height: 100%; }

body {
  max-width: 100%;
  overflow-x: hidden; }

.ccm-page {
  padding: 0;
  line-height: 1.7;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #000;
  /* Grid */ }
  .ccm-page h1,
  .ccm-page h2,
  .ccm-page h3,
  .ccm-page p,
  .ccm-page table,
  .ccm-page ol,
  .ccm-page ul {
    margin: 0 0 15px;
    padding: 0; }
  .ccm-page h1,
  .ccm-page h2,
  .ccm-page h3 {
    line-height: 1.2; }
  .ccm-page ol,
  .ccm-page ul {
    list-style: none; }
  .ccm-page a {
    color: inherit; }
  .ccm-page img {
    height: auto;
    max-width: 100%;
    vertical-align: bottom; }
    @media (min-width: 768px) {
      .ccm-page img {
        width: auto; } }
  .ccm-page .pull-left {
    float: left !important; }
  .ccm-page .pull-right {
    float: right !important; }
  .ccm-page .text-left {
    text-align: left !important; }
  .ccm-page .text-center {
    text-align: center !important; }
  .ccm-page .text-right {
    text-align: right !important; }
  .ccm-page .row {
    display: block;
    margin: 0 auto;
    padding: 0;
    max-width: 1300px; }
    .ccm-page .row:after {
      display: table;
      content: '';
      clear: both; }
    .ccm-page .row.full {
      max-width: none; }
    .ccm-page .row .column {
      float: left;
      display: block;
      margin: 0;
      padding: 0 10px;
      width: 100%; }
      .ccm-page .row .column .row {
        margin-left: -10px;
        margin-right: -10px; }
    .ccm-page .row .base-1 {
      width: 8.33333%; }
    .ccm-page .row .base-2 {
      width: 16.66667%; }
    .ccm-page .row .base-3 {
      width: 25%; }
    .ccm-page .row .base-4 {
      width: 33.33333%; }
    .ccm-page .row .base-5 {
      width: 41.66667%; }
    .ccm-page .row .base-6 {
      width: 50%; }
    .ccm-page .row .base-7 {
      width: 58.33333%; }
    .ccm-page .row .base-8 {
      width: 66.66667%; }
    .ccm-page .row .base-9 {
      width: 75%; }
    .ccm-page .row .base-10 {
      width: 83.33333%; }
    .ccm-page .row .base-11 {
      width: 91.66667%; }
    .ccm-page .row .base-12 {
      width: 100%; }
    @media only screen and (min-width: 480px) {
      .ccm-page .row .small-1 {
        width: 8.33333%; }
      .ccm-page .row .small-offset-1 {
        margin-left: 8.33333% !important; }
      .ccm-page .row .small-2 {
        width: 16.66667%; }
      .ccm-page .row .small-offset-2 {
        margin-left: 16.66667% !important; }
      .ccm-page .row .small-3 {
        width: 25%; }
      .ccm-page .row .small-offset-3 {
        margin-left: 25% !important; }
      .ccm-page .row .small-4 {
        width: 33.33333%; }
      .ccm-page .row .small-offset-4 {
        margin-left: 33.33333% !important; }
      .ccm-page .row .small-5 {
        width: 41.66667%; }
      .ccm-page .row .small-offset-5 {
        margin-left: 41.66667% !important; }
      .ccm-page .row .small-6 {
        width: 50%; }
      .ccm-page .row .small-offset-6 {
        margin-left: 50% !important; }
      .ccm-page .row .small-7 {
        width: 58.33333%; }
      .ccm-page .row .small-offset-7 {
        margin-left: 58.33333% !important; }
      .ccm-page .row .small-8 {
        width: 66.66667%; }
      .ccm-page .row .small-offset-8 {
        margin-left: 66.66667% !important; }
      .ccm-page .row .small-9 {
        width: 75%; }
      .ccm-page .row .small-offset-9 {
        margin-left: 75% !important; }
      .ccm-page .row .small-10 {
        width: 83.33333%; }
      .ccm-page .row .small-offset-10 {
        margin-left: 83.33333% !important; }
      .ccm-page .row .small-11 {
        width: 91.66667%; }
      .ccm-page .row .small-offset-11 {
        margin-left: 91.66667% !important; }
      .ccm-page .row .small-12 {
        width: 100%; }
      .ccm-page .row .small-offset-12 {
        margin-left: 100% !important; } }
    @media only screen and (min-width: 768px) {
      .ccm-page .row .medium-1 {
        width: 8.33333%; }
      .ccm-page .row .medium-offset-1 {
        margin-left: 8.33333% !important; }
      .ccm-page .row .medium-2 {
        width: 16.66667%; }
      .ccm-page .row .medium-offset-2 {
        margin-left: 16.66667% !important; }
      .ccm-page .row .medium-3 {
        width: 25%; }
      .ccm-page .row .medium-offset-3 {
        margin-left: 25% !important; }
      .ccm-page .row .medium-4 {
        width: 33.33333%; }
      .ccm-page .row .medium-offset-4 {
        margin-left: 33.33333% !important; }
      .ccm-page .row .medium-5 {
        width: 41.66667%; }
      .ccm-page .row .medium-offset-5 {
        margin-left: 41.66667% !important; }
      .ccm-page .row .medium-6 {
        width: 50%; }
      .ccm-page .row .medium-offset-6 {
        margin-left: 50% !important; }
      .ccm-page .row .medium-7 {
        width: 58.33333%; }
      .ccm-page .row .medium-offset-7 {
        margin-left: 58.33333% !important; }
      .ccm-page .row .medium-8 {
        width: 66.66667%; }
      .ccm-page .row .medium-offset-8 {
        margin-left: 66.66667% !important; }
      .ccm-page .row .medium-9 {
        width: 75%; }
      .ccm-page .row .medium-offset-9 {
        margin-left: 75% !important; }
      .ccm-page .row .medium-10 {
        width: 83.33333%; }
      .ccm-page .row .medium-offset-10 {
        margin-left: 83.33333% !important; }
      .ccm-page .row .medium-11 {
        width: 91.66667%; }
      .ccm-page .row .medium-offset-11 {
        margin-left: 91.66667% !important; }
      .ccm-page .row .medium-12 {
        width: 100%; }
      .ccm-page .row .medium-offset-12 {
        margin-left: 100% !important; } }
    @media only screen and (min-width: 1024px) {
      .ccm-page .row .large-1 {
        width: 8.33333%; }
      .ccm-page .row .large-offset-1 {
        margin-left: 8.33333% !important; }
      .ccm-page .row .large-2 {
        width: 16.66667%; }
      .ccm-page .row .large-offset-2 {
        margin-left: 16.66667% !important; }
      .ccm-page .row .large-3 {
        width: 25%; }
      .ccm-page .row .large-offset-3 {
        margin-left: 25% !important; }
      .ccm-page .row .large-4 {
        width: 33.33333%; }
      .ccm-page .row .large-offset-4 {
        margin-left: 33.33333% !important; }
      .ccm-page .row .large-5 {
        width: 41.66667%; }
      .ccm-page .row .large-offset-5 {
        margin-left: 41.66667% !important; }
      .ccm-page .row .large-6 {
        width: 50%; }
      .ccm-page .row .large-offset-6 {
        margin-left: 50% !important; }
      .ccm-page .row .large-7 {
        width: 58.33333%; }
      .ccm-page .row .large-offset-7 {
        margin-left: 58.33333% !important; }
      .ccm-page .row .large-8 {
        width: 66.66667%; }
      .ccm-page .row .large-offset-8 {
        margin-left: 66.66667% !important; }
      .ccm-page .row .large-9 {
        width: 75%; }
      .ccm-page .row .large-offset-9 {
        margin-left: 75% !important; }
      .ccm-page .row .large-10 {
        width: 83.33333%; }
      .ccm-page .row .large-offset-10 {
        margin-left: 83.33333% !important; }
      .ccm-page .row .large-11 {
        width: 91.66667%; }
      .ccm-page .row .large-offset-11 {
        margin-left: 91.66667% !important; }
      .ccm-page .row .large-12 {
        width: 100%; }
      .ccm-page .row .large-offset-12 {
        margin-left: 100% !important; } }
    @media only screen and (min-width: 1200px) {
      .ccm-page .row .xlarge-1 {
        width: 8.33333%; }
      .ccm-page .row .xlarge-offset-1 {
        margin-left: 8.33333% !important; }
      .ccm-page .row .xlarge-2 {
        width: 16.66667%; }
      .ccm-page .row .xlarge-offset-2 {
        margin-left: 16.66667% !important; }
      .ccm-page .row .xlarge-3 {
        width: 25%; }
      .ccm-page .row .xlarge-offset-3 {
        margin-left: 25% !important; }
      .ccm-page .row .xlarge-4 {
        width: 33.33333%; }
      .ccm-page .row .xlarge-offset-4 {
        margin-left: 33.33333% !important; }
      .ccm-page .row .xlarge-5 {
        width: 41.66667%; }
      .ccm-page .row .xlarge-offset-5 {
        margin-left: 41.66667% !important; }
      .ccm-page .row .xlarge-6 {
        width: 50%; }
      .ccm-page .row .xlarge-offset-6 {
        margin-left: 50% !important; }
      .ccm-page .row .xlarge-7 {
        width: 58.33333%; }
      .ccm-page .row .xlarge-offset-7 {
        margin-left: 58.33333% !important; }
      .ccm-page .row .xlarge-8 {
        width: 66.66667%; }
      .ccm-page .row .xlarge-offset-8 {
        margin-left: 66.66667% !important; }
      .ccm-page .row .xlarge-9 {
        width: 75%; }
      .ccm-page .row .xlarge-offset-9 {
        margin-left: 75% !important; }
      .ccm-page .row .xlarge-10 {
        width: 83.33333%; }
      .ccm-page .row .xlarge-offset-10 {
        margin-left: 83.33333% !important; }
      .ccm-page .row .xlarge-11 {
        width: 91.66667%; }
      .ccm-page .row .xlarge-offset-11 {
        margin-left: 91.66667% !important; }
      .ccm-page .row .xlarge-12 {
        width: 100%; }
      .ccm-page .row .xlarge-offset-12 {
        margin-left: 100% !important; } }
    @media only screen and (min-width: 1450px) {
      .ccm-page .row .xxlarge-1 {
        width: 8.33333%; }
      .ccm-page .row .xxlarge-offset-1 {
        margin-left: 8.33333% !important; }
      .ccm-page .row .xxlarge-2 {
        width: 16.66667%; }
      .ccm-page .row .xxlarge-offset-2 {
        margin-left: 16.66667% !important; }
      .ccm-page .row .xxlarge-3 {
        width: 25%; }
      .ccm-page .row .xxlarge-offset-3 {
        margin-left: 25% !important; }
      .ccm-page .row .xxlarge-4 {
        width: 33.33333%; }
      .ccm-page .row .xxlarge-offset-4 {
        margin-left: 33.33333% !important; }
      .ccm-page .row .xxlarge-5 {
        width: 41.66667%; }
      .ccm-page .row .xxlarge-offset-5 {
        margin-left: 41.66667% !important; }
      .ccm-page .row .xxlarge-6 {
        width: 50%; }
      .ccm-page .row .xxlarge-offset-6 {
        margin-left: 50% !important; }
      .ccm-page .row .xxlarge-7 {
        width: 58.33333%; }
      .ccm-page .row .xxlarge-offset-7 {
        margin-left: 58.33333% !important; }
      .ccm-page .row .xxlarge-8 {
        width: 66.66667%; }
      .ccm-page .row .xxlarge-offset-8 {
        margin-left: 66.66667% !important; }
      .ccm-page .row .xxlarge-9 {
        width: 75%; }
      .ccm-page .row .xxlarge-offset-9 {
        margin-left: 75% !important; }
      .ccm-page .row .xxlarge-10 {
        width: 83.33333%; }
      .ccm-page .row .xxlarge-offset-10 {
        margin-left: 83.33333% !important; }
      .ccm-page .row .xxlarge-11 {
        width: 91.66667%; }
      .ccm-page .row .xxlarge-offset-11 {
        margin-left: 91.66667% !important; }
      .ccm-page .row .xxlarge-12 {
        width: 100%; }
      .ccm-page .row .xxlarge-offset-12 {
        margin-left: 100% !important; } }
    @media only screen and (min-width: 960px) {
      .ccm-page .row .menuswitch-1 {
        width: 8.33333%; }
      .ccm-page .row .menuswitch-offset-1 {
        margin-left: 8.33333% !important; }
      .ccm-page .row .menuswitch-2 {
        width: 16.66667%; }
      .ccm-page .row .menuswitch-offset-2 {
        margin-left: 16.66667% !important; }
      .ccm-page .row .menuswitch-3 {
        width: 25%; }
      .ccm-page .row .menuswitch-offset-3 {
        margin-left: 25% !important; }
      .ccm-page .row .menuswitch-4 {
        width: 33.33333%; }
      .ccm-page .row .menuswitch-offset-4 {
        margin-left: 33.33333% !important; }
      .ccm-page .row .menuswitch-5 {
        width: 41.66667%; }
      .ccm-page .row .menuswitch-offset-5 {
        margin-left: 41.66667% !important; }
      .ccm-page .row .menuswitch-6 {
        width: 50%; }
      .ccm-page .row .menuswitch-offset-6 {
        margin-left: 50% !important; }
      .ccm-page .row .menuswitch-7 {
        width: 58.33333%; }
      .ccm-page .row .menuswitch-offset-7 {
        margin-left: 58.33333% !important; }
      .ccm-page .row .menuswitch-8 {
        width: 66.66667%; }
      .ccm-page .row .menuswitch-offset-8 {
        margin-left: 66.66667% !important; }
      .ccm-page .row .menuswitch-9 {
        width: 75%; }
      .ccm-page .row .menuswitch-offset-9 {
        margin-left: 75% !important; }
      .ccm-page .row .menuswitch-10 {
        width: 83.33333%; }
      .ccm-page .row .menuswitch-offset-10 {
        margin-left: 83.33333% !important; }
      .ccm-page .row .menuswitch-11 {
        width: 91.66667%; }
      .ccm-page .row .menuswitch-offset-11 {
        margin-left: 91.66667% !important; }
      .ccm-page .row .menuswitch-12 {
        width: 100%; }
      .ccm-page .row .menuswitch-offset-12 {
        margin-left: 100% !important; } }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.ccm-page .alert {
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  border-radius: 2px; }
  .ccm-page .alert.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #5bc0de; }
  .ccm-page .alert.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #5cb85c; }
  .ccm-page .alert.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #d9534f; }
  .ccm-page .alert.alert-warning {
    background-color: #fcf8e3;
    border-color: #faf2cc;
    color: #f0ad4e; }

.ccm-page table {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  width: 100%; }
  .ccm-page table tr td,
  .ccm-page table tr th {
    padding: 8px;
    vertical-align: middle;
    text-align: left; }
  .ccm-page table tr td {
    border-top: 1px solid #ddd; }
  .ccm-page table tr:last-child td {
    border-bottom: 1px solid #ddd; }
  .ccm-page table.table-striped tr:nth-child(odd) {
    background: #ededed; }

.ccm-page .form-group {
  display: block;
  margin: 0 0 15px; }

.ccm-page .form-control {
  outline: none;
  border: 0;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  margin: 0;
  padding: 0 15px;
  background: #ededed;
  width: 100%;
  height: 45px;
  line-height: 45px;
  font-family: inherit;
  font-size: inherit;
  color: #000; }

.ccm-page .control-label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
  cursor: pointer; }

.ccm-page textarea.form-control {
  resize: none;
  padding: 10px 15px;
  height: auto;
  line-height: 1.7; }

.ccm-page .ccm-input-date {
  margin-right: 0 !important; }

.ccm-page .input-group:after {
  display: table;
  content: '';
  clear: both; }

.ccm-page .input-group .input-group-datepicker {
  float: left;
  width: calc(100% - 45px); }
  .ccm-page .input-group .input-group-datepicker .form-control {
    width: 100%; }

.ccm-page .input-group .input-group-addon {
  display: block;
  border: 0;
  border-radius: 3px;
  background: #ddd;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3); }
  .ccm-page .input-group .input-group-addon:first-child {
    float: left;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .ccm-page .input-group .input-group-addon:last-child {
    float: right;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.ccm-page .input-group .form-control {
  width: calc(100% - 45px); }
  .ccm-page .input-group .form-control:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .ccm-page .input-group .form-control:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.ccm-page .main .row.white-row a.button,
.ccm-page .button {
  line-height: 50px;
  background-color: #4084c5;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
  padding: 0 80px 0 30px;
  width: auto;
  position: relative;
  cursor: pointer;
  margin-top: 20px; }
  .ccm-page .main .row.white-row a.button::after,
  .ccm-page .button::after {
    content: '\f0da';
    position: absolute;
    right: 0px;
    top: 0px;
    height: 50px;
    width: 50px;
    background-color: #3475b2;
    text-align: center;
    font-family: fontAwesome;
    font-size: 20px; }
  .ccm-page .main .row.white-row a.button:hover,
  .ccm-page .button:hover {
    background-color: #102941;
    color: #fff; }
    .ccm-page .main .row.white-row a.button:hover::after,
    .ccm-page .button:hover::after {
      background-color: #000; }
  .ccm-page .main .row.white-row a.button .fa,
  .ccm-page .button .fa {
    padding-left: 5px; }
  .ccm-page .main .row.white-row a.button.dark-button,
  .ccm-page .button.dark-button {
    background-color: #102941;
    color: #fff; }
    .ccm-page .main .row.white-row a.button.dark-button::after,
    .ccm-page .button.dark-button::after {
      background-color: #000; }
    .ccm-page .main .row.white-row a.button.dark-button:hover,
    .ccm-page .button.dark-button:hover {
      background-color: #4084c5; }
      .ccm-page .main .row.white-row a.button.dark-button:hover::after,
      .ccm-page .button.dark-button:hover::after {
        background-color: #3475b2; }

.ccm-page input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0; }

.ccm-page .slider-button {
  line-height: 50px;
  background-color: #4084c5;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
  padding: 0px 100px 0px 50px;
  width: auto;
  position: relative;
  cursor: pointer;
  margin-top: 20px; }
  .ccm-page .slider-button::after {
    content: '\f0da';
    position: absolute;
    right: 0px;
    top: 0px;
    height: 50px;
    width: 50px;
    background-color: #3475b2;
    text-align: center;
    font-family: fontAwesome;
    font-size: 20px; }
  .ccm-page .slider-button:hover {
    background-color: #102941; }
    .ccm-page .slider-button:hover::after {
      background-color: #000; }

.ccm-page .page-not-found .button {
  line-height: 50px;
  background-color: #4084c5;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
  padding: 0px 100px 0px 50px;
  width: auto;
  position: relative;
  cursor: pointer;
  margin-top: 20px; }
  .ccm-page .page-not-found .button::after {
    content: '\f0da';
    position: absolute;
    right: 0px;
    top: 0px;
    height: 50px;
    width: 50px;
    background-color: #3475b2;
    text-align: center;
    font-family: fontAwesome;
    font-size: 20px; }
  .ccm-page .page-not-found .button:hover {
    background-color: #102941; }
    .ccm-page .page-not-found .button:hover::after {
      background-color: #000; }

.ccm-page .quotation-form-row .dynamic-form-submit-wrap .button {
  background-color: #ff9600;
  border: none;
  padding: 0px 100px 0px 100px;
  margin-top: 0px;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.65); }
  .ccm-page .quotation-form-row .dynamic-form-submit-wrap .button:hover {
    background-color: #102941;
    color: #fff; }

.ccm-page .label {
  display: inline;
  padding: 2px 6px;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px; }
  .ccm-page .label.label-default {
    background-color: #f6f6f6; }
  .ccm-page .label.label-primary {
    background-color: #337ab7; }
  .ccm-page .label.label-danger {
    background-color: #d9534f; }
  .ccm-page .label.label-info {
    background-color: #5bc0de; }
  .ccm-page .label.label-success {
    background-color: #5cb85c; }
  .ccm-page .label.label-warning {
    background-color: #f0ad4e; }

.ccm-page {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; }
  @media only screen and (min-width: 1200px) {
    .ccm-page {
      overflow: inherit; } }
  .ccm-page .logo-row {
    margin-bottom: 50px; }
  .ccm-page .column.logos img {
    padding: 10px 20px;
    margin: 10px 0; }
    @media only screen and (min-width: 768px) {
      .ccm-page .column.logos img {
        border: 1px solid #58b0e9; }
        .ccm-page .column.logos img:hover {
          opacity: .8;
          border: 1px solid #102941; } }
  .ccm-page .column.logos ul li:before {
    display: none; }
  .ccm-page .column.logos .slick-slide {
    padding: 10px; }
  .ccm-page .column.logos .slick-dots li button:before {
    font-size: 16px;
    color: #3d85c7; }
  .ccm-page .column.logos button.slick-prev.slick-arrow {
    color: black;
    left: 10px;
    z-index: 333; }
    @media only screen and (min-width: 1024px) {
      .ccm-page .column.logos button.slick-prev.slick-arrow {
        left: -20px; } }
    .ccm-page .column.logos button.slick-prev.slick-arrow::before {
      color: black;
      font-family: 'FontAwesome';
      content: "\f104";
      font-size: 36px; }
  .ccm-page .column.logos button.slick-next.slick-arrow {
    color: black;
    right: 10px; }
    @media only screen and (min-width: 1024px) {
      .ccm-page .column.logos button.slick-next.slick-arrow {
        right: -20px; } }
    .ccm-page .column.logos button.slick-next.slick-arrow::before {
      color: black;
      font-family: 'FontAwesome';
      content: "\f105";
      font-size: 36px; }
  @media only screen and (min-width: 1024px) {
    .ccm-page .header .stick .mainnavigation {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding-left: 50px; } }
  .ccm-page .header .stick .menu-row nav {
    background-color: rgba(16, 41, 65, 0.9); }
  @media only screen and (min-width: 1024px) {
    .ccm-page .header .header-top.stick .contact-button {
      position: fixed;
      top: 79px; } }
  .ccm-page .header .menu-row nav {
    display: none;
    position: relative;
    z-index: 10;
    width: 100%;
    background-color: rgba(16, 41, 65, 0.8);
    top: 55px;
    position: absolute; }
    @media only screen and (min-width: 960px) {
      .ccm-page .header .menu-row nav {
        background-color: transparent;
        max-width: calc(100% - 80px);
        position: relative;
        top: auto; } }
    @media only screen and (min-width: 768px) {
      .ccm-page .header .menu-row nav {
        top: 0; } }
    @media only screen and (min-width: 1024px) {
      .ccm-page .header .menu-row nav {
        top: 0; } }
    @media only screen and (min-width: 1200px) {
      .ccm-page .header .menu-row nav {
        max-width: 100%; } }
    .ccm-page .header .menu-row nav ul.menu {
      text-align: left;
      margin: 0px; }
      .ccm-page .header .menu-row nav ul.menu li {
        position: relative;
        display: inline-block;
        width: 100%;
        margin: 0px 1px;
        line-height: 40px;
        border-top: 1px solid #31475d; }
        @media only screen and (min-width: 960px) {
          .ccm-page .header .menu-row nav ul.menu li {
            line-height: 79px;
            height: 79px;
            border-top: 0px; } }
        .ccm-page .header .menu-row nav ul.menu li a {
          padding: 0 18px;
          text-decoration: none;
          color: #fff;
          text-align: left;
          height: 100%;
          width: 100%;
          display: inline-block;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          -webkit-transition: all .3s ease-in-out;
          -moz-transition: all .3s ease-in-out;
          -o-transition: all .3s ease-in-out;
          transition: all .3s ease-in-out;
          border-bottom: 0px solid #4084c5; }
          @media only screen and (min-width: 1450px) {
            .ccm-page .header .menu-row nav ul.menu li a {
              padding: 0 30px; } }
          @media only screen and (min-width: 960px) {
            .ccm-page .header .menu-row nav ul.menu li a.menu__item__anchor--active, .ccm-page .header .menu-row nav ul.menu li a.menu__item__anchor--current, .ccm-page .header .menu-row nav ul.menu li a:hover {
              text-decoration: none;
              color: #85bbef;
              border-bottom: 5px solid #4084c5;
              -webkit-transition: all .3s ease-in-out;
              -moz-transition: all .3s ease-in-out;
              -o-transition: all .3s ease-in-out;
              transition: all .3s ease-in-out; } }
        .ccm-page .header .menu-row nav ul.menu li ul.menu__submenu {
          display: none;
          margin-bottom: 0px; }
          @media only screen and (min-width: 960px) {
            .ccm-page .header .menu-row nav ul.menu li ul.menu__submenu {
              position: absolute;
              top: 79px;
              left: 0px; } }
        .ccm-page .header .menu-row nav ul.menu li.drop-down-menu {
          padding: 0px;
          position: relative; }
          .ccm-page .header .menu-row nav ul.menu li.drop-down-menu ul li {
            background-color: #4084c5;
            line-height: 40px;
            height: 40px;
            float: left;
            min-width: 100%;
            border-bottom: 1px solid #9b958c; }
            .ccm-page .header .menu-row nav ul.menu li.drop-down-menu ul li a {
              font-size: 14px;
              color: #fff; }
              .ccm-page .header .menu-row nav ul.menu li.drop-down-menu ul li a:hover {
                color: #102941; }
            .ccm-page .header .menu-row nav ul.menu li.drop-down-menu ul li::after {
              font-family: fontAwesome;
              content: '\f105';
              position: absolute;
              right: 5px;
              top: 0px;
              color: #fff; }
          @media only screen and (min-width: 960px) {
            .ccm-page .header .menu-row nav ul.menu li.drop-down-menu:hover ul.menu__submenu {
              display: block; } }
          .ccm-page .header .menu-row nav ul.menu li.drop-down-menu .open-dropdown {
            color: #102941;
            background-color: #fff;
            position: absolute;
            top: 0;
            right: 0px;
            width: 44px;
            line-height: 40px;
            text-align: center;
            font-size: 16px; }
            @media only screen and (min-width: 960px) {
              .ccm-page .header .menu-row nav ul.menu li.drop-down-menu .open-dropdown {
                background-color: transparent;
                color: #fff;
                width: 25px;
                line-height: 79px;
                right: -10px; } }
        @media only screen and (min-width: 960px) {
          .ccm-page .header .menu-row nav ul.menu li {
            width: auto;
            border-top: none; } }
    @media only screen and (min-width: 960px) {
      .ccm-page .header .menu-row nav {
        display: block; } }
  .ccm-page .header .menu-row .menu-toggle {
    display: inline-block;
    margin: 0 auto;
    right: 15px;
    top: -30px;
    color: #002942;
    position: absolute;
    font-size: 35px; }
    @media only screen and (min-width: 768px) {
      .ccm-page .header .menu-row .menu-toggle {
        top: -80px;
        z-index: 444444; } }
    @media only screen and (min-width: 960px) {
      .ccm-page .header .menu-row .menu-toggle {
        display: none; } }
  .ccm-page .header .searchbar {
    background-color: #102941;
    height: 100px;
    display: none;
    position: absolute;
    top: 155px;
    z-index: 300;
    width: 100%; }
    @media only screen and (min-width: 960px) {
      .ccm-page .header .searchbar {
        position: relative;
        top: 0px; } }
    .ccm-page .header .searchbar .ccm-search-block-form {
      width: 100%;
      text-align: center; }
      .ccm-page .header .searchbar .ccm-search-block-form input.ccm-search-block-submit {
        font-family: 'FontAwesome';
        background-color: transparent;
        color: #fff;
        font-size: 25px;
        border: none;
        max-width: 18%;
        cursor: pointer; }
      .ccm-page .header .searchbar .ccm-search-block-form input.ccm-search-block-text {
        width: 70%;
        height: 50px;
        margin-top: 25px;
        background-color: rgba(255, 255, 255, 0.1);
        border: none;
        font-size: 20px;
        padding-left: 40px;
        cursor: pointer; }
        .ccm-page .header .searchbar .ccm-search-block-form input.ccm-search-block-text::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: rgba(255, 255, 255, 0.4); }
        .ccm-page .header .searchbar .ccm-search-block-form input.ccm-search-block-text::-moz-placeholder {
          /* Firefox 19+ */
          color: rgba(255, 255, 255, 0.4); }
        .ccm-page .header .searchbar .ccm-search-block-form input.ccm-search-block-text:-ms-input-placeholder {
          /* IE 10+ */
          color: rgba(255, 255, 255, 0.4); }
        .ccm-page .header .searchbar .ccm-search-block-form input.ccm-search-block-text:-moz-placeholder {
          /* Firefox 18- */
          color: rgba(255, 255, 255, 0.4); }
  .ccm-page .header .header-top {
    height: 100px;
    display: inline-block;
    width: 100%;
    z-index: 200;
    position: relative;
    border-bottom: 1px solid #9b958c;
    top: 0;
    position: fixed; }
    @media only screen and (min-width: 768px) {
      .ccm-page .header .header-top {
        position: relative; } }
    @media only screen and (min-width: 1200px) {
      .ccm-page .header .header-top {
        height: 150px; } }
    .ccm-page .header .header-top .contact-button {
      position: absolute;
      right: 10px;
      bottom: -65px;
      display: none;
      height: 65px;
      max-width: 230px;
      width: 100%;
      background: #ff9300;
      background-position: bottom;
      background-size: cover;
      color: white;
      text-transform: uppercase;
      text-align: center;
      padding-top: 25px; }
      @media only screen and (min-width: 1024px) {
        .ccm-page .header .header-top .contact-button {
          bottom: -150px;
          display: block; } }
      @media only screen and (min-width: 1200px) {
        .ccm-page .header .header-top .contact-button {
          bottom: -65px; } }
      .ccm-page .header .header-top .contact-button::before {
        border-top: 35px solid #ff9300;
        border-left: 115px solid transparent;
        border-right: 115px solid transparent;
        content: "";
        height: 0;
        left: 0;
        position: absolute;
        bottom: -35px;
        width: 0; }
      .ccm-page .header .header-top .contact-button a {
        text-decoration: none;
        font-weight: bold; }
    .ccm-page .header .header-top .logo {
      background-color: #f7f7f7;
      height: 105px;
      text-align: left;
      padding-left: 20px;
      position: relative;
      border-bottom: 1px solid #9b958c; }
      @media only screen and (min-width: 768px) {
        .ccm-page .header .header-top .logo {
          text-align: center; } }
      @media only screen and (min-width: 1200px) {
        .ccm-page .header .header-top .logo {
          height: 100px; } }
      @media only screen and (min-width: 1200px) {
        .ccm-page .header .header-top .logo {
          height: 150px; } }
      .ccm-page .header .header-top .logo svg {
        position: relative;
        width: 300px;
        top: 10px;
        left: 20px; }
        @media screen and (max-width: 390px) {
          .ccm-page .header .header-top .logo svg {
            width: 260px;
            top: 15px; } }
        @media only screen and (min-width: 768px) {
          .ccm-page .header .header-top .logo svg {
            left: 0; } }
        @media only screen and (min-width: 1200px) {
          .ccm-page .header .header-top .logo svg {
            width: auto;
            padding: 0 20px;
            padding-top: 10px;
            max-width: 400px; } }
        @media only screen and (min-width: 1450px) {
          .ccm-page .header .header-top .logo svg {
            max-width: 450px; } }
    .ccm-page .header .header-top .column {
      padding: 0px; }
    .ccm-page .header .header-top .menu-row .top-row {
      height: 70px;
      width: 100%;
      position: relative; }
      @media only screen and (min-width: 1200px) {
        .ccm-page .header .header-top .menu-row .top-row {
          background-color: #f7f7f7; } }
      .ccm-page .header .header-top .menu-row .top-row .show-searchbar {
        width: 70px;
        height: auto;
        position: absolute;
        left: 0px;
        right: auto;
        top: 0px;
        display: block;
        background-color: #102941;
        color: #fff;
        text-align: center;
        line-height: 55px;
        font-size: 25px;
        cursor: pointer;
        z-index: 2;
        display: none; }
        @media only screen and (min-width: 960px) {
          .ccm-page .header .header-top .menu-row .top-row .show-searchbar {
            right: 0px;
            left: auto;
            line-height: 80px; } }
        @media only screen and (min-width: 1200px) {
          .ccm-page .header .header-top .menu-row .top-row .show-searchbar {
            line-height: 71px; } }
        .ccm-page .header .header-top .menu-row .top-row .show-searchbar .fa-search {
          display: inline-block; }
        .ccm-page .header .header-top .menu-row .top-row .show-searchbar .fa-times {
          display: none; }
        .ccm-page .header .header-top .menu-row .top-row .show-searchbar:hover {
          background-color: #4084c5; }
        .ccm-page .header .header-top .menu-row .top-row .show-searchbar.active {
          background-color: #4084c5; }
          .ccm-page .header .header-top .menu-row .top-row .show-searchbar.active .fa-search {
            display: none; }
          .ccm-page .header .header-top .menu-row .top-row .show-searchbar.active .fa-times {
            display: inline-block; }
      .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container {
        display: none; }
        @media only screen and (min-width: 768px) {
          .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container {
            display: inline-block; } }
        .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .list-item-link {
          height: 70px;
          display: inline-block;
          position: absolute;
          left: 51px;
          top: -8px;
          z-index: 333; }
          @media only screen and (min-width: 768px) {
            .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .list-item-link {
              left: 0px;
              top: -85px; } }
          @media only screen and (min-width: 1200px) {
            .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .list-item-link {
              position: relative;
              left: auto;
              top: auto; } }
          .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .list-item-link:last-child {
            top: -8px;
            left: 100px; }
            @media only screen and (min-width: 768px) {
              .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .list-item-link:last-child {
                right: 0px;
                top: -85px;
                left: auto; } }
            @media only screen and (min-width: 1200px) {
              .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .list-item-link:last-child {
                position: relative;
                right: auto;
                top: auto; } }
          .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .list-item-link:first-child {
            display: none; }
            @media only screen and (min-width: 1200px) {
              .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .list-item-link:first-child {
                display: inline-block; } }
        .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .ccm-list-item {
          display: inline-block;
          width: 50px;
          height: 40px; }
          @media only screen and (min-width: 768px) {
            .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .ccm-list-item {
              width: 230px;
              height: 70px; } }
          @media only screen and (min-width: 1450px) {
            .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .ccm-list-item {
              width: 290px; } }
          .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .ccm-list-item .list-item-icon {
            width: 50px;
            height: 40px;
            display: inline-block;
            float: left;
            color: #4084c5;
            line-height: 70px;
            text-align: center;
            font-size: 25px; }
            @media only screen and (min-width: 768px) {
              .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .ccm-list-item .list-item-icon {
                width: 70px;
                height: 60px; } }
          .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .ccm-list-item .list-time-text-warp {
            display: none;
            width: 160px;
            line-height: 1.4; }
            @media only screen and (min-width: 768px) {
              .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .ccm-list-item .list-time-text-warp {
                display: inline-block; } }
            @media only screen and (min-width: 1450px) {
              .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .ccm-list-item .list-time-text-warp {
                width: 200px; } }
            .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .ccm-list-item .list-time-text-warp .list-item-title {
              display: inline-block;
              width: 100%;
              margin-top: 12px;
              font-family: "Montserrat", sans-serif;
              font-weight: bold;
              color: #4084c5;
              font-size: 14px;
              text-transform: uppercase; }
            .ccm-page .header .header-top .menu-row .top-row .ccm-list-generator-container .ccm-list-item .list-time-text-warp .list-item-description {
              color: #102941;
              display: inline-block;
              width: 100%;
              font-size: 14px;
              margin-top: 0; }
    .ccm-page .header .header-top .menu-row .bottom-row {
      background-color: transparent;
      height: 55px;
      width: 100%;
      background: transparent;
      position: relative;
      margin-top: -125px; }
      @media only screen and (min-width: 768px) {
        .ccm-page .header .header-top .menu-row .bottom-row {
          margin-top: -71px; } }
      @media only screen and (min-width: 960px) {
        .ccm-page .header .header-top .menu-row .bottom-row {
          height: 80px;
          background: rgba(16, 41, 65, 0.3); } }
      @media only screen and (min-width: 1200px) {
        .ccm-page .header .header-top .menu-row .bottom-row {
          margin-top: 0px; } }
      .ccm-page .header .header-top .menu-row .bottom-row .ccm-list-generator-container {
        display: none;
        position: absolute;
        left: 10px;
        top: 0px;
        z-index: 500; }
        @media only screen and (min-width: 960px) {
          .ccm-page .header .header-top .menu-row .bottom-row .ccm-list-generator-container {
            right: 100px;
            left: auto; } }
        @media only screen and (min-width: 1200px) {
          .ccm-page .header .header-top .menu-row .bottom-row .ccm-list-generator-container {
            right: 10px; } }
        .ccm-page .header .header-top .menu-row .bottom-row .ccm-list-generator-container .ccm-list-item {
          display: inline-block; }
          .ccm-page .header .header-top .menu-row .bottom-row .ccm-list-generator-container .ccm-list-item .list-item-link {
            display: inline-block; }
            .ccm-page .header .header-top .menu-row .bottom-row .ccm-list-generator-container .ccm-list-item .list-item-link:hover {
              opacity: 0.7; }
          .ccm-page .header .header-top .menu-row .bottom-row .ccm-list-generator-container .ccm-list-item .list-item-icon {
            display: inline-block;
            width: 45px;
            float: left;
            color: #fff;
            line-height: 55px;
            text-align: center;
            font-size: 25px; }
            @media only screen and (min-width: 960px) {
              .ccm-page .header .header-top .menu-row .bottom-row .ccm-list-generator-container .ccm-list-item .list-item-icon {
                line-height: 79px; } }
  .ccm-page .header .header-slider {
    height: 700px;
    background-color: #fff;
    margin-top: -85px; }
    @media only screen and (min-width: 1024px) {
      .ccm-page .header .header-slider {
        height: 954px; } }
  .ccm-page .header .header-image {
    min-height: 400px;
    background: url(../images/met-groep-header.jpg) no-repeat;
    background-position: left -350px center;
    background-size: auto 400px;
    margin-top: -85px;
    position: relative; }
    @media only screen and (min-width: 1024px) {
      .ccm-page .header .header-image {
        background-size: cover;
        background-position: center center; } }
    .ccm-page .header .header-image .breadcrumb-wrapper {
      width: 100%;
      background-color: rgba(238, 238, 238, 0.8);
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: 43px; }
      .ccm-page .header .header-image .breadcrumb-wrapper .bc-item,
      .ccm-page .header .header-image .breadcrumb-wrapper a.bc-item {
        display: inline-block;
        font-size: 12px;
        line-height: 12px;
        color: #102941;
        padding: 22px 20px 0px 20px;
        height: 43px;
        text-transform: uppercase;
        position: relative;
        text-decoration: none;
        font-weight: bold; }
        .ccm-page .header .header-image .breadcrumb-wrapper .bc-item::before,
        .ccm-page .header .header-image .breadcrumb-wrapper a.bc-item::before {
          width: 20px;
          height: 20px;
          content: '';
          display: block;
          position: absolute;
          background-color: #102941;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 2; }
        .ccm-page .header .header-image .breadcrumb-wrapper .bc-item .left-line,
        .ccm-page .header .header-image .breadcrumb-wrapper .bc-item .right-line,
        .ccm-page .header .header-image .breadcrumb-wrapper a.bc-item .left-line,
        .ccm-page .header .header-image .breadcrumb-wrapper a.bc-item .right-line {
          width: 50%;
          position: absolute;
          height: 2px;
          display: block;
          background-color: #102941;
          top: -1px;
          left: 0px;
          z-index: 1; }
        .ccm-page .header .header-image .breadcrumb-wrapper .bc-item .right-line,
        .ccm-page .header .header-image .breadcrumb-wrapper a.bc-item .right-line {
          right: 0px;
          left: auto; }
        .ccm-page .header .header-image .breadcrumb-wrapper .bc-item:hover, .ccm-page .header .header-image .breadcrumb-wrapper .bc-item.active,
        .ccm-page .header .header-image .breadcrumb-wrapper a.bc-item:hover,
        .ccm-page .header .header-image .breadcrumb-wrapper a.bc-item.active {
          color: #4084c5; }
          .ccm-page .header .header-image .breadcrumb-wrapper .bc-item:hover::before, .ccm-page .header .header-image .breadcrumb-wrapper .bc-item.active::before,
          .ccm-page .header .header-image .breadcrumb-wrapper a.bc-item:hover::before,
          .ccm-page .header .header-image .breadcrumb-wrapper a.bc-item.active::before {
            background-color: #4084c5; }
  .ccm-page .main .row.top-blocks {
    height: 449px;
    margin-top: -165px; }
    .ccm-page .main .row.top-blocks .row {
      height: 449px;
      position: relative; }
      .ccm-page .main .row.top-blocks .row .top-block-right,
      .ccm-page .main .row.top-blocks .row .top-block-left {
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
        min-height: 391px;
        background: url(../images/top-block-left.jpg);
        background-size: cover;
        padding: 0px;
        margin-top: 25px; }
      .ccm-page .main .row.top-blocks .row .top-block-left::before {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipV;
        -ms-filter: "FlipV";
        content: '';
        background: url(../images/blue-box-sider.png) no-repeat;
        position: absolute;
        top: -25px;
        left: -310px;
        width: 310px;
        height: 425px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      .ccm-page .main .row.top-blocks .row .top-block-right {
        background: url(../images/top-block-right.jpg); }
        .ccm-page .main .row.top-blocks .row .top-block-right::after {
          content: '';
          background: url(../images/blue-box-sider.png) no-repeat;
          position: absolute;
          top: -25px;
          right: -310px;
          width: 310px;
          height: 425px;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          transition: all 0.3s ease; }
      .ccm-page .main .row.top-blocks .row .overlay {
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 391px;
        padding: 10px;
        padding-top: 40px;
        line-height: 25px;
        position: relative; }
        @media only screen and (min-width: 1024px) {
          .ccm-page .main .row.top-blocks .row .overlay {
            padding: 40px; } }
        .ccm-page .main .row.top-blocks .row .overlay .bottom-content {
          position: relative;
          padding-top: 30px; }
          .ccm-page .main .row.top-blocks .row .overlay .bottom-content p {
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            transition: all 0.3s ease;
            opacity: 0;
            overflow: hidden;
            text-transform: uppercase;
            font-weight: bold; }
          .ccm-page .main .row.top-blocks .row .overlay .bottom-content::before {
            font-size: 30px;
            font-family: fontAwesome;
            content: '\f107';
            display: block;
            width: 100%;
            text-align: center;
            color: #4084c5;
            position: absolute;
            top: 0px;
            left: 0px; }
        .ccm-page .main .row.top-blocks .row .overlay h2 {
          font-size: 28px;
          font-family: "Montserrat", sans-serif;
          text-transform: uppercase;
          color: #fff;
          text-align: center; }
          @media only screen and (min-width: 1024px) {
            .ccm-page .main .row.top-blocks .row .overlay h2 {
              font-size: 32px; } }
        .ccm-page .main .row.top-blocks .row .overlay p {
          color: #fff;
          text-align: center;
          font-family: "Poppins", sans-serif; }
        .ccm-page .main .row.top-blocks .row .overlay .buttons-wrapper {
          width: 100%;
          position: absolute;
          left: 0px;
          bottom: 0px;
          padding: 20px 10px 10px 10px; }
          .ccm-page .main .row.top-blocks .row .overlay .buttons-wrapper .button {
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            transition: all 0.3s ease;
            width: 100%;
            padding: 0px;
            margin-top: 0px;
            text-align: center;
            color: rgba(255, 255, 255, 0.33);
            background-color: rgba(255, 255, 255, 0.15);
            font-weight: 600; }
            .ccm-page .main .row.top-blocks .row .overlay .buttons-wrapper .button::after {
              display: none; }
      .ccm-page .main .row.top-blocks .row .active {
        min-height: 449px;
        margin-top: 0px;
        -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.71);
        -moz-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.71);
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.71); }
        .ccm-page .main .row.top-blocks .row .active.top-block-right::after {
          top: 0px;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          transition: all 0.3s ease; }
        .ccm-page .main .row.top-blocks .row .active.top-block-left::before {
          top: 0px;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          transition: all 0.3s ease; }
        .ccm-page .main .row.top-blocks .row .active .overlay {
          padding-top: 65px;
          height: 449px;
          background-color: rgba(65, 133, 198, 0); }
          .ccm-page .main .row.top-blocks .row .active .overlay .bottom-content::before {
            display: none; }
          .ccm-page .main .row.top-blocks .row .active .overlay .bottom-content p {
            opacity: 1;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            transition: all 0.3s ease; }
          .ccm-page .main .row.top-blocks .row .active .overlay .buttons-wrapper {
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            transition: all 0.3s ease;
            background-color: rgba(0, 0, 0, 0.3); }
            .ccm-page .main .row.top-blocks .row .active .overlay .buttons-wrapper .button {
              color: #102941;
              background-color: #fff; }
              .ccm-page .main .row.top-blocks .row .active .overlay .buttons-wrapper .button:hover {
                color: #fff;
                background-color: #4084c5; }
  .ccm-page .main .row.white-row {
    background-color: #fff; }
    .ccm-page .main .row.white-row.searchresults {
      padding-bottom: 80px; }
      .ccm-page .main .row.white-row.searchresults input.ccm-search-block-submit {
        font-family: 'FontAwesome';
        background-color: transparent;
        color: #4084c5;
        font-size: 25px;
        border: none;
        max-width: 10%;
        cursor: pointer; }
      .ccm-page .main .row.white-row.searchresults input.ccm-search-block-text {
        width: 88%;
        height: 50px;
        margin-top: 25px;
        background-color: rgba(16, 41, 65, 0.8);
        border: none;
        font-size: 20px;
        padding-left: 40px;
        cursor: pointer;
        color: #fff; }
        .ccm-page .main .row.white-row.searchresults input.ccm-search-block-text::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: rgba(255, 255, 255, 0.4); }
        .ccm-page .main .row.white-row.searchresults input.ccm-search-block-text::-moz-placeholder {
          /* Firefox 19+ */
          color: rgba(255, 255, 255, 0.4); }
        .ccm-page .main .row.white-row.searchresults input.ccm-search-block-text:-ms-input-placeholder {
          /* IE 10+ */
          color: rgba(255, 255, 255, 0.4); }
        .ccm-page .main .row.white-row.searchresults input.ccm-search-block-text:-moz-placeholder {
          /* Firefox 18- */
          color: rgba(255, 255, 255, 0.4); }
      .ccm-page .main .row.white-row.searchresults .searchResult {
        border-bottom: 1px solid #4084c5; }
        .ccm-page .main .row.white-row.searchresults .searchResult a {
          text-decoration: none; }
    .ccm-page .main .row.white-row h1 {
      margin-top: 40px;
      margin-bottom: 40px;
      background: url(../images/slider-text-corner.png) no-repeat 0 0;
      color: red;
      padding: 15px 0px 0px 37px;
      color: #102941;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 28px; }
      @media only screen and (min-width: 768px) {
        .ccm-page .main .row.white-row h1 {
          font-size: 36px; } }
      .ccm-page .main .row.white-row h1 span {
        font-weight: normal; }
    .ccm-page .main .row.white-row h2 {
      font-size: 22px;
      text-transform: uppercase;
      padding-bottom: 0px;
      margin-bottom: 5px;
      margin-top: 20px; }
    .ccm-page .main .row.white-row h3 {
      font-size: 18px;
      text-transform: uppercase;
      padding-bottom: 0px;
      margin-bottom: 5px;
      margin-top: 20px; }
    .ccm-page .main .row.white-row ul li {
      margin-bottom: 6px; }
      .ccm-page .main .row.white-row ul li::before {
        font-family: fontAwesome;
        content: '\f0da';
        margin-right: 10px; }
    .ccm-page .main .row.white-row a {
      color: #4084c5; }
      .ccm-page .main .row.white-row a:hover {
        color: #102941; }
    .ccm-page .main .row.white-row .image-title {
      display: inline-block;
      width: 100%; }
      .ccm-page .main .row.white-row .image-title p {
        font-weight: bold;
        padding: 10px 0px 5px 0px;
        border-bottom: 1px solid #4084c5;
        color: #102941;
        float: left; }
    .ccm-page .main .row.white-row .left-side-image {
      display: none; }
      @media only screen and (min-width: 768px) {
        .ccm-page .main .row.white-row .left-side-image {
          display: block; } }
      .ccm-page .main .row.white-row .left-side-image.contact-block {
        display: block; }
    .ccm-page .main .row.white-row.main-content {
      padding-top: 60px;
      padding-bottom: 60px;
      text-align: center; }
      .ccm-page .main .row.white-row.main-content svg {
        max-width: 95px; }
      .ccm-page .main .row.white-row.main-content a.read-more {
        background-color: transparent;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;
        color: #102941;
        padding: 0px; }
        .ccm-page .main .row.white-row.main-content a.read-more .fa {
          margin-left: 5px; }
        .ccm-page .main .row.white-row.main-content a.read-more:hover {
          color: #4084c5; }
        .ccm-page .main .row.white-row.main-content a.read-more::after {
          display: none; }
      .ccm-page .main .row.white-row.main-content h2 {
        padding-top: 10px;
        width: 100%;
        text-align: center;
        color: #4084c5;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 36px; }
        .ccm-page .main .row.white-row.main-content h2 span.logo-part-one {
          color: #102941;
          font-family: 'Russo One', sans-serif; }
        .ccm-page .main .row.white-row.main-content h2 span.logo-part-two {
          color: #4084c5;
          font-family: 'Open Sans', sans-serif;
          font-weight: 300; }
      .ccm-page .main .row.white-row.main-content p {
        color: #102941; }
    .ccm-page .main .row.white-row.pointers .pointer-block {
      width: 100%;
      display: inline-block; }
      @media only screen and (min-width: 768px) {
        .ccm-page .main .row.white-row.pointers .pointer-block {
          height: 240px; } }
      .ccm-page .main .row.white-row.pointers .pointer-block .pointer-image {
        height: 100%;
        width: 20%;
        float: left;
        text-align: center;
        padding-top: 20px; }
        @media only screen and (min-width: 768px) {
          .ccm-page .main .row.white-row.pointers .pointer-block .pointer-image {
            width: 25%; } }
        .ccm-page .main .row.white-row.pointers .pointer-block .pointer-image img {
          max-width: auto;
          width: auto; }
      .ccm-page .main .row.white-row.pointers .pointer-block .pointer-text {
        height: 100%;
        width: 74%;
        display: inline-block;
        float: left;
        font-size: 14px;
        text-align: left; }
        .ccm-page .main .row.white-row.pointers .pointer-block .pointer-text h3 {
          font-size: 18px;
          color: #102941;
          text-transform: uppercase; }
        .ccm-page .main .row.white-row.pointers .pointer-block .pointer-text p {
          color: #4084c5; }
    .ccm-page .main .row.white-row.pointers .left-pointers {
      position: relative; }
      @media only screen and (min-width: 768px) {
        .ccm-page .main .row.white-row.pointers .left-pointers {
          padding: 60px 0px 0px 0px;
          border-right: 1px solid #4084c5; } }
      @media only screen and (min-width: 768px) {
        .ccm-page .main .row.white-row.pointers .left-pointers .pointer-text,
        .ccm-page .main .row.white-row.pointers .left-pointers .pointer-image {
          float: right; } }
      @media only screen and (min-width: 768px) {
        .ccm-page .main .row.white-row.pointers .left-pointers .pointer-text {
          text-align: right; } }
      @media only screen and (min-width: 768px) {
        .ccm-page .main .row.white-row.pointers .left-pointers::after {
          background: url(../images/pointer-bottom-line.png);
          height: 40px;
          width: 21px;
          display: block;
          position: absolute;
          content: '';
          bottom: -40px;
          right: -11px; } }
    @media only screen and (min-width: 768px) {
      .ccm-page .main .row.white-row.pointers .right-pointers {
        padding: 60px 0px 0px 0px; } }
    @media only screen and (min-width: 768px) {
      .ccm-page .main .row.white-row.callback-row {
        margin-top: 80px;
        margin-bottom: 0; } }
    .ccm-page .main .row.white-row.callback-row .callback-left {
      text-align: center;
      padding: 60px;
      display: none; }
      @media only screen and (min-width: 768px) {
        .ccm-page .main .row.white-row.callback-row .callback-left {
          display: block; } }
    .ccm-page .main .row.white-row.callback-row .callback-right {
      padding: 20px; }
      @media only screen and (min-width: 768px) {
        .ccm-page .main .row.white-row.callback-row .callback-right {
          padding: 60px; } }
      .ccm-page .main .row.white-row.callback-row .callback-right h2 {
        font-family: "Montserrat", sans-serif;
        font-size: 26px;
        text-transform: uppercase;
        font-weight: bold;
        color: #4084c5; }
        @media only screen and (min-width: 1024px) {
          .ccm-page .main .row.white-row.callback-row .callback-right h2 {
            font-size: 36px; } }
        .ccm-page .main .row.white-row.callback-row .callback-right h2 span {
          color: #102941; }
        .ccm-page .main .row.white-row.callback-row .callback-right h2::before {
          content: '\f2a0';
          font-family: fontAwesome;
          margin-right: 10px;
          color: #102941; }
    .ccm-page .main .row.white-row .ccm-list-generator-container .ccm-list-item {
      display: inline-block;
      width: 100%;
      font-size: 16px;
      margin-bottom: 10px;
      color: #4084c5; }
      .ccm-page .main .row.white-row .ccm-list-generator-container .ccm-list-item .list-item-icon {
        display: inline-block;
        width: 40px;
        float: left; }
      .ccm-page .main .row.white-row .ccm-list-generator-container .ccm-list-item .list-item-title {
        display: inline-block; }
      .ccm-page .main .row.white-row .ccm-list-generator-container .ccm-list-item .list-item-link:hover {
        color: #102941; }
  .ccm-page .main .row.grey-row {
    background-color: #eeeeee;
    height: 483px;
    margin-top: 50px; }
  .ccm-page .main .row.three-blocks {
    margin-top: -447px;
    margin-bottom: 100px; }
    .ccm-page .main .row.three-blocks h2 {
      font-size: 26px;
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      color: #fff;
      text-align: left; }
    .ccm-page .main .row.three-blocks p {
      color: #fff;
      font-size: 14px;
      margin-top: 20px;
      line-height: 23px; }
    .ccm-page .main .row.three-blocks ul li {
      font-size: 14px;
      color: white; }
      .ccm-page .main .row.three-blocks ul li::before {
        font-family: 'FontAwesome';
        content: '\f105';
        padding-right: 5px; }
    .ccm-page .main .row.three-blocks .block-one {
      background: url(../images/block-bg-one.jpg);
      background-size: cover;
      display: block;
      position: relative;
      padding: 30px 20px; }
      @media only screen and (min-width: 1024px) {
        .ccm-page .main .row.three-blocks .block-one {
          height: 640px; } }
      .ccm-page .main .row.three-blocks .block-one::before {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipV;
        -ms-filter: "FlipV";
        content: '';
        background: url(../images/blue-box-sider.png) no-repeat;
        position: absolute;
        top: 0px;
        left: -310px;
        width: 310px;
        height: 540px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease; }
    .ccm-page .main .row.three-blocks .block-two {
      background-color: green;
      background: url(../images/block-bg-two.jpg);
      background-size: cover;
      padding: 0px; }
      @media only screen and (min-width: 1024px) {
        .ccm-page .main .row.three-blocks .block-two {
          height: 640px; } }
      .ccm-page .main .row.three-blocks .block-two .overlay {
        padding: 30px 20px;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        display: block; }
        @media only screen and (min-width: 1024px) {
          .ccm-page .main .row.three-blocks .block-two .overlay {
            height: 640px; } }
      .ccm-page .main .row.three-blocks .block-two.with-sider::before {
        content: '';
        background: url(../images/blue-box-sider.png) no-repeat;
        position: absolute;
        top: 0px;
        right: -310px;
        width: 310px;
        height: 540px; }
    .ccm-page .main .row.three-blocks .block-three {
      padding: 30px 20px;
      background-color: purple;
      background: url(../images/block-bg-one.jpg);
      position: relative;
      background-size: cover; }
      .ccm-page .main .row.three-blocks .block-three::after {
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%; }
      .ccm-page .main .row.three-blocks .block-three.no-overlay::after {
        display: none; }
      .ccm-page .main .row.three-blocks .block-three .overlay {
        position: relative;
        z-index: 11; }
      @media only screen and (min-width: 1024px) {
        .ccm-page .main .row.three-blocks .block-three {
          height: 640px; } }
      .ccm-page .main .row.three-blocks .block-three::before {
        content: '';
        background: url(../images/blue-box-sider.png) no-repeat;
        position: absolute;
        top: 0px;
        right: -310px;
        width: 310px;
        height: 540px; }
    .ccm-page .main .row.three-blocks .block-four {
      padding: 30px 20px;
      background-color: purple;
      background: url(../images/block-bg-one.jpg);
      position: relative;
      background-size: cover; }
      @media only screen and (min-width: 1024px) {
        .ccm-page .main .row.three-blocks .block-four {
          height: 640px; } }
      .ccm-page .main .row.three-blocks .block-four::before {
        content: '';
        background: url(../images/blue-box-sider.png) no-repeat;
        position: absolute;
        top: 0px;
        right: -310px;
        width: 310px;
        height: 540px; }
  .ccm-page .main .row.quotation-row {
    margin-bottom: 80px; }
    .ccm-page .main .row.quotation-row h2 {
      text-transform: uppercase;
      color: #102941;
      font-size: 36px;
      font-family: "Montserrat", sans-serif;
      position: relative;
      padding-left: 75px;
      margin-bottom: 35px; }
      .ccm-page .main .row.quotation-row h2 span {
        color: #4084c5; }
      .ccm-page .main .row.quotation-row h2::before {
        content: '';
        background: url(../images/quote-icon.png) no-repeat;
        width: 53px;
        height: 44px;
        position: absolute;
        left: 0px;
        top: -10px; }
    .ccm-page .main .row.quotation-row p {
      color: #102941; }
  .ccm-page .main .row.quotation-form-row {
    background: url(../images/quote-bg.jpg) no-repeat;
    background-size: cover;
    margin-bottom: 80px; }
    .ccm-page .main .row.quotation-form-row .dynamic-form-container span.dynamic-form-error {
      bottom: -15px;
      right: 20px;
      top: auto; }
    .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-error {
      border: solid 2px red; }
    .ccm-page .main .row.quotation-form-row .dynamic-form-container .human-check-wrap {
      padding: 10px;
      background: transparent;
      color: #fff;
      width: auto;
      margin-top: 15px; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .human-check-wrap .humanity-check {
        background-color: #fff;
        border: none; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .human-check-wrap span.dynamic-form-error {
        bottom: 0px;
        right: auto;
        top: auto;
        left: 10px; }
    .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control {
      min-height: 80px;
      margin-bottom: 10px;
      position: relative;
      display: inline-block; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control .validate-asteriks {
        display: none; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control textarea,
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="text"],
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="tel"],
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="email"] {
        position: absolute;
        bottom: 0px;
        left: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        border-radius: 0px;
        background-color: #efefef;
        width: 600px;
        max-width: 98%;
        padding-left: 70px; }
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control textarea::-webkit-input-placeholder,
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="text"]::-webkit-input-placeholder,
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="tel"]::-webkit-input-placeholder,
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="email"]::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: rgba(16, 41, 65, 0.64); }
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control textarea::-moz-placeholder,
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="text"]::-moz-placeholder,
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="tel"]::-moz-placeholder,
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="email"]::-moz-placeholder {
          /* Firefox 19+ */
          color: rgba(16, 41, 65, 0.64); }
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control textarea:-ms-input-placeholder,
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="text"]:-ms-input-placeholder,
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="tel"]:-ms-input-placeholder,
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="email"]:-ms-input-placeholder {
          /* IE 10+ */
          color: rgba(16, 41, 65, 0.64); }
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control textarea:-moz-placeholder,
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="text"]:-moz-placeholder,
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="tel"]:-moz-placeholder,
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control input[type="email"]:-moz-placeholder {
          /* Firefox 18- */
          color: rgba(16, 41, 65, 0.64); }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control textarea {
        width: 1240px;
        height: 180px;
        max-width: 98%; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.icon-control::after {
        font-family: fontAwesome;
        content: '\f007';
        position: absolute;
        left: 10px;
        bottom: 0px;
        width: 48px;
        height: 45px;
        color: #fff;
        line-height: 45px;
        text-align: center;
        background-color: #102941; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.name-control::after {
        content: '\f007'; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.message-control {
        height: 250px; }
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.message-control label {
          color: #fff;
          font-family: "Montserrat", sans-serif;
          text-transform: uppercase;
          font-size: 24px;
          margin-top: 30px; }
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.message-control::after {
          height: 180px;
          content: '\f0e5'; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.name-control, .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.streetnr-control, .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.company-control, .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.street-control {
        height: 115px; }
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.name-control label, .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.streetnr-control label, .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.company-control label, .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.street-control label {
          color: #fff;
          font-family: "Montserrat", sans-serif;
          text-transform: uppercase;
          font-size: 24px;
          margin-top: 30px; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.company-control::after {
        content: '\f1ad'; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.email-control::after {
        content: '\f0e0'; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.phone-control::after {
        content: '\f095'; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.street-control::after {
        content: '\f015'; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.city-control::after {
        content: '\f041'; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.zipcode-control::after {
        content: '\f2bb'; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.name-control {
        height: 80px; }
        @media only screen and (min-width: 1024px) {
          .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.name-control {
            height: 115px; } }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.streetnr-control {
        height: 80px; }
        @media only screen and (min-width: 1024px) {
          .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.streetnr-control {
            height: 115px; } }
        .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.streetnr-control::after {
          content: '\f162'; }
      .ccm-page .main .row.quotation-form-row .dynamic-form-container .dynamic-form-control.phone-control::after {
        content: '\f095'; }
  .ccm-page .main .row.project-row {
    background: url(../images/amster-hotel-bg.jpg) no-repeat top left #102941;
    min-height: 565px; }
    .ccm-page .main .row.project-row .project-title {
      padding-top: 60px; }
      .ccm-page .main .row.project-row .project-title h2, .ccm-page .main .row.project-row .project-title h3 {
        color: #4084c5;
        width: 100%;
        text-align: center;
        font-size: 24px;
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase;
        margin: 0px; }
        @media only screen and (min-width: 768px) {
          .ccm-page .main .row.project-row .project-title h2, .ccm-page .main .row.project-row .project-title h3 {
            font-size: 36px; } }
      .ccm-page .main .row.project-row .project-title h2 {
        color: #fff;
        font-size: 30px; }
        @media only screen and (min-width: 768px) {
          .ccm-page .main .row.project-row .project-title h2 {
            font-size: 58px; } }
  .ccm-page .main .row.project-slider-row {
    margin-top: -330px;
    position: relative; }
    .ccm-page .main .row.project-slider-row .project-text-wrap {
      padding: 20px;
      background-color: #fff; }
      @media only screen and (min-width: 768px) {
        .ccm-page .main .row.project-slider-row .project-text-wrap {
          padding: 35px 60px; } }
      .ccm-page .main .row.project-slider-row .project-text-wrap::after {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipV;
        -ms-filter: "FlipV";
        content: '';
        background: url(../images/project-slider-sider.png) no-repeat;
        position: absolute;
        bottom: -42px;
        right: -231px;
        width: 231px;
        height: 425px; }
      .ccm-page .main .row.project-slider-row .project-text-wrap h2 {
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase;
        font-size: 26px;
        font-weight: normal;
        color: #102941; }
      .ccm-page .main .row.project-slider-row .project-text-wrap p {
        color: #102941;
        font-size: 14px; }
    .ccm-page .main .row.project-slider-row .project-slider-wrap {
      padding: 0px; }
      .ccm-page .main .row.project-slider-row .project-slider-wrap::before {
        content: '';
        background: url(../images/project-slider-sider.png) no-repeat;
        position: absolute;
        top: 0px;
        left: -231px;
        width: 231px;
        height: 425px;
        display: block; }
      .ccm-page .main .row.project-slider-row .project-slider-wrap .project-slider {
        display: none; }
        @media only screen and (min-width: 1024px) {
          .ccm-page .main .row.project-slider-row .project-slider-wrap .project-slider {
            display: block; } }
        .ccm-page .main .row.project-slider-row .project-slider-wrap .project-slider .slide {
          height: 500px; }
        .ccm-page .main .row.project-slider-row .project-slider-wrap .project-slider .slick-dots {
          position: absolute;
          left: 15px;
          bottom: 50%;
          width: 30px;
          height: auto;
          padding: 0px;
          margin: 0px;
          transform: translateY(50%); }
          .ccm-page .main .row.project-slider-row .project-slider-wrap .project-slider .slick-dots li {
            width: 30px;
            height: 30px;
            float: left;
            padding: 0px;
            margin: 0px;
            margin-bottom: 12px; }
            .ccm-page .main .row.project-slider-row .project-slider-wrap .project-slider .slick-dots li button {
              background-color: #fff;
              width: 100%;
              height: 100%;
              opacity: 0.5; }
              .ccm-page .main .row.project-slider-row .project-slider-wrap .project-slider .slick-dots li button::before {
                display: none; }
            .ccm-page .main .row.project-slider-row .project-slider-wrap .project-slider .slick-dots li.slick-active button {
              opacity: 1; }
  .ccm-page .footer {
    position: relative; }
    .ccm-page .footer .sticky-footer {
      position: fixed;
      bottom: 0;
      z-index: 22222222;
      left: 0;
      width: 100%;
      height: 60px;
      background: #ff6c00;
      color: white; }
      .ccm-page .footer .sticky-footer a {
        float: left;
        width: 50%;
        padding-top: 15px;
        padding-bottom: 18px;
        padding-left: 10px;
        text-align: center;
        border-right: 1px solid #214869;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold; }
        .ccm-page .footer .sticky-footer a:last-child {
          float: right;
          padding-right: 10px; }
      @media only screen and (min-width: 768px) {
        .ccm-page .footer .sticky-footer {
          display: none; } }
    .ccm-page .footer h2 {
      color: #fff;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 22px;
      letter-spacing: 2px;
      margin-top: 30px; }
    .ccm-page .footer .footer-logo {
      height: 100px;
      text-align: center;
      margin-bottom: -50px; }
      .ccm-page .footer .footer-logo svg {
        padding: 0 20px;
        max-width: 460px;
        width: 100%;
        z-index: 100;
        position: relative; }
    .ccm-page .footer .footer-triangle {
      width: 2500px;
      border-left: 1250px solid transparent;
      border-right: 1250px solid transparent;
      border-top: 120px solid #fff;
      position: absolute;
      left: 50%;
      transform: translateX(-50%); }
    .ccm-page .footer .footer-top {
      padding-top: 140px;
      background-color: #102941;
      border-bottom: 1px solid rgba(255, 255, 255, 0.11);
      text-align: center; }
      @media only screen and (min-width: 1024px) {
        .ccm-page .footer .footer-top {
          min-height: 380px;
          padding-top: 195px; } }
      .ccm-page .footer .footer-top .row {
        display: none; }
        @media only screen and (min-width: 1024px) {
          .ccm-page .footer .footer-top .row {
            display: block; } }
      .ccm-page .footer .footer-top .menu-toggle {
        display: none; }
      .ccm-page .footer .footer-top .menu {
        margin-top: 30px; }
        .ccm-page .footer .footer-top .menu li {
          display: inline-block; }
          .ccm-page .footer .footer-top .menu li a {
            font-size: 16px;
            color: #729ac0;
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: none;
            padding: 15px; }
            @media only screen and (min-width: 1200px) {
              .ccm-page .footer .footer-top .menu li a {
                padding: 30px; } }
            .ccm-page .footer .footer-top .menu li a.menu__item__anchor--current, .ccm-page .footer .footer-top .menu li a:hover {
              text-decoration: none;
              color: #fff; }
    .ccm-page .footer .footer-mid {
      min-height: 185px;
      background-color: #102941;
      border-bottom: 1px solid rgba(255, 255, 255, 0.11);
      padding-top: 66px; }
      .ccm-page .footer .footer-mid .contact-info-wrapper {
        text-align: center;
        margin: 0px auto;
        background-color: #4084c5;
        max-width: 800px;
        position: relative;
        padding: 0px 30px;
        -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5); }
        .ccm-page .footer .footer-mid .contact-info-wrapper::before {
          font-family: fontAwesome;
          content: '\f041';
          position: absolute;
          left: 50%;
          top: -50px;
          color: #fff;
          font-size: 50px;
          transform: translateX(-50%); }
        .ccm-page .footer .footer-mid .contact-info-wrapper .ccm-list-generator-container .ccm-list-item {
          display: inline-block;
          margin-right: 20px;
          margin-left: 20px; }
          .ccm-page .footer .footer-mid .contact-info-wrapper .ccm-list-generator-container .ccm-list-item .list-item-link {
            text-decoration: none; }
          .ccm-page .footer .footer-mid .contact-info-wrapper .ccm-list-generator-container .ccm-list-item .list-item-icon {
            display: inline-block;
            line-height: 62px;
            color: #fff;
            font-size: 24px;
            margin-right: 5px; }
          .ccm-page .footer .footer-mid .contact-info-wrapper .ccm-list-generator-container .ccm-list-item .list-item-title {
            display: inline-block;
            line-height: 62px;
            font-size: 16px;
            text-transform: uppercase;
            color: #fff; }
    .ccm-page .footer .footer-bottom {
      min-height: 185px;
      background-color: #102941;
      border-bottom: 1px solid rgba(255, 255, 255, 0.11);
      display: none; }
      @media only screen and (min-width: 1024px) {
        .ccm-page .footer .footer-bottom {
          display: block; } }
      .ccm-page .footer .footer-bottom p {
        color: #729ac0; }
      .ccm-page .footer .footer-bottom .image-wrap p {
        text-transform: uppercase;
        margin-top: 10px; }
    .ccm-page .footer .footer-copyright {
      background-color: #102941;
      color: #729ac0; }
      .ccm-page .footer .footer-copyright .footer-bottom-left p {
        float: left; }
      .ccm-page .footer .footer-copyright .footer-bottom-left,
      .ccm-page .footer .footer-copyright .footer-bottom-right {
        text-align: center;
        line-height: 30px;
        font-size: 12px; }
        @media only screen and (min-width: 1024px) {
          .ccm-page .footer .footer-copyright .footer-bottom-left,
          .ccm-page .footer .footer-copyright .footer-bottom-right {
            line-height: 54px;
            font-size: 14px; } }
        .ccm-page .footer .footer-copyright .footer-bottom-left p,
        .ccm-page .footer .footer-copyright .footer-bottom-right p {
          margin-bottom: 0px;
          text-transform: uppercase; }
          .ccm-page .footer .footer-copyright .footer-bottom-left p a,
          .ccm-page .footer .footer-copyright .footer-bottom-right p a {
            text-decoration: none;
            color: #fff; }
            .ccm-page .footer .footer-copyright .footer-bottom-left p a:hover,
            .ccm-page .footer .footer-copyright .footer-bottom-right p a:hover {
              text-decoration: underline; }
        @media only screen and (min-width: 1024px) {
          .ccm-page .footer .footer-copyright .footer-bottom-left,
          .ccm-page .footer .footer-copyright .footer-bottom-right {
            text-align: right; } }
      .ccm-page .footer .footer-copyright .footer-bottom-left {
        text-align: center; }
        @media only screen and (min-width: 1024px) {
          .ccm-page .footer .footer-copyright .footer-bottom-left {
            text-align: left; } }
    @media only screen and (min-width: 1024px) {
      .ccm-page .footer::after {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipV;
        -ms-filter: "FlipV";
        content: '';
        background: url(../images/footer-sider.png) no-repeat;
        position: absolute;
        top: 130px;
        right: 0px;
        width: 153px;
        height: 382px; }
      .ccm-page .footer::before {
        content: '';
        background: url(../images/footer-sider.png) no-repeat;
        position: absolute;
        top: 130px;
        left: 0px;
        width: 153px;
        height: 382px;
        display: block; } }

.scrollToTop {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 34px;
  text-align: center;
  background: #102941;
  color: #4084c5;
  position: fixed;
  bottom: 70px;
  right: 10px;
  display: none;
  border: solid 1px #4084c5;
  z-index: 1000; }
  .scrollToTop:hover {
    text-decoration: none;
    color: #fff;
    background: #4084c5; }

.ccm-page .header .header-slider {
  width: 100%;
  max-width: 100%;
  margin-bottom: 30px; }
  .ccm-page .header .header-slider .slide {
    height: 954px;
    background-size: cover;
    background-position: center;
    position: relative; }
    .ccm-page .header .header-slider .slide .slider-text {
      position: absolute;
      top: 170px;
      left: 30px;
      min-width: 300px;
      height: 355px;
      background: url(../images/slider-text-corner.png) no-repeat top left;
      padding: 40px;
      font-size: 14px;
      color: #e1e1e1;
      text-transform: uppercase;
      font-family: "Poppins", sans-serif;
      letter-spacing: 1.5px; }
      @media only screen and (min-width: 768px) {
        .ccm-page .header .header-slider .slide .slider-text {
          width: 640px;
          height: 355px;
          top: 235px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 18px; } }
      .ccm-page .header .header-slider .slide .slider-text h2 {
        color: #fff;
        font-weight: normal;
        font-size: 30px;
        padding: 0px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300; }
        @media only screen and (min-width: 768px) {
          .ccm-page .header .header-slider .slide .slider-text h2 {
            font-size: 60px; } }
        .ccm-page .header .header-slider .slide .slider-text h2 .first-word {
          font-family: 'Russo One', sans-serif; }
  .ccm-page .header .header-slider .slick-arrow {
    left: auto;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.25);
    width: 40px;
    height: 40px;
    top: 50%;
    display: none;
    opacity: 1;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out; }
    @media only screen and (min-width: 1024px) {
      .ccm-page .header .header-slider .slick-arrow {
        opacity: 1;
        width: 100px;
        height: 100px;
        top: 40%; } }
    .ccm-page .header .header-slider .slick-arrow:hover {
      background: #337ab7;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out; }
    .ccm-page .header .header-slider .slick-arrow::before {
      line-height: 40px;
      font-size: 35px;
      font-family: 'fontAwesome';
      text-align: center; }
      @media only screen and (min-width: 1024px) {
        .ccm-page .header .header-slider .slick-arrow::before {
          line-height: 95px; } }
    .ccm-page .header .header-slider .slick-arrow.slick-prev {
      left: 2px; }
      .ccm-page .header .header-slider .slick-arrow.slick-prev::before {
        content: '\f104'; }
    .ccm-page .header .header-slider .slick-arrow.slick-next {
      right: 2px; }
      .ccm-page .header .header-slider .slick-arrow.slick-next::before {
        content: '\f105'; }

.ccm-page .dynamic-form-container.callback-form {
  margin-top: 40px;
  margin-bottom: 10px; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form span.dynamic-form-error {
    bottom: 10px;
    right: -10px;
    top: auto; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form .dynamic-form-error {
    border: solid 2px red; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form .human-check-wrap {
    padding: 0px;
    color: #4084c5;
    width: auto;
    background: none;
    height: 30px; }
    .ccm-page .dynamic-form-container.callback-form .dynamic-form .human-check-wrap span.dynamic-form-error {
      bottom: 0px;
      right: auto;
      top: auto; }
    .ccm-page .dynamic-form-container.callback-form .dynamic-form .human-check-wrap .humanity-check {
      background-color: #a9a9a9;
      border: none; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form input[type="text"],
  .ccm-page .dynamic-form-container.callback-form .dynamic-form input[type="tel"],
  .ccm-page .dynamic-form-container.callback-form .dynamic-form textarea,
  .ccm-page .dynamic-form-container.callback-form .dynamic-form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 0px;
    background-color: #d0d0d0; }
    .ccm-page .dynamic-form-container.callback-form .dynamic-form input[type="text"]::-webkit-input-placeholder,
    .ccm-page .dynamic-form-container.callback-form .dynamic-form input[type="tel"]::-webkit-input-placeholder,
    .ccm-page .dynamic-form-container.callback-form .dynamic-form textarea::-webkit-input-placeholder,
    .ccm-page .dynamic-form-container.callback-form .dynamic-form select::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(16, 41, 65, 0.64); }
    .ccm-page .dynamic-form-container.callback-form .dynamic-form input[type="text"]::-moz-placeholder,
    .ccm-page .dynamic-form-container.callback-form .dynamic-form input[type="tel"]::-moz-placeholder,
    .ccm-page .dynamic-form-container.callback-form .dynamic-form textarea::-moz-placeholder,
    .ccm-page .dynamic-form-container.callback-form .dynamic-form select::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(16, 41, 65, 0.64); }
    .ccm-page .dynamic-form-container.callback-form .dynamic-form input[type="text"]:-ms-input-placeholder,
    .ccm-page .dynamic-form-container.callback-form .dynamic-form input[type="tel"]:-ms-input-placeholder,
    .ccm-page .dynamic-form-container.callback-form .dynamic-form textarea:-ms-input-placeholder,
    .ccm-page .dynamic-form-container.callback-form .dynamic-form select:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(16, 41, 65, 0.64); }
    .ccm-page .dynamic-form-container.callback-form .dynamic-form input[type="text"]:-moz-placeholder,
    .ccm-page .dynamic-form-container.callback-form .dynamic-form input[type="tel"]:-moz-placeholder,
    .ccm-page .dynamic-form-container.callback-form .dynamic-form textarea:-moz-placeholder,
    .ccm-page .dynamic-form-container.callback-form .dynamic-form select:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(16, 41, 65, 0.64); }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form #date-time_dt_pub {
    width: 95px;
    margin-right: 20px !important; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form #date-time_m,
  .ccm-page .dynamic-form-container.callback-form .dynamic-form #date-time_h {
    width: 60px;
    margin-right: 5px !important; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form .dynamic-form-submit-wrap {
    width: 100%; }
    @media only screen and (min-width: 1024px) {
      .ccm-page .dynamic-form-container.callback-form .dynamic-form .dynamic-form-submit-wrap {
        width: 250px; } }
    .ccm-page .dynamic-form-container.callback-form .dynamic-form .dynamic-form-submit-wrap input[type="submit"] {
      line-height: 50px;
      background-color: #ff6c00;
      color: #fff;
      border: 0;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 16px;
      display: inline-block;
      padding: 0px 50px 0px 50px;
      width: 100%;
      margin-top: 0;
      position: relative;
      cursor: pointer;
      -webkit-appearance: none;
      border-radius: 0; }
      .ccm-page .dynamic-form-container.callback-form .dynamic-form .dynamic-form-submit-wrap input[type="submit"]:hover {
        background-color: #102941; }
        .ccm-page .dynamic-form-container.callback-form .dynamic-form .dynamic-form-submit-wrap input[type="submit"]:hover::after {
          background-color: #000; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form .validate-asteriks {
    display: none; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form label {
    font-size: 18px;
    color: #102941; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form .control-icon label {
    width: 48px;
    height: 45px;
    background-color: #4084c5;
    display: block;
    position: absolute;
    font-size: 15px; }
    .ccm-page .dynamic-form-container.callback-form .dynamic-form .control-icon label::after {
      font-family: fontAwesome;
      content: '\f007';
      position: absolute;
      left: 0px;
      top: 0px;
      width: 48px;
      height: 45px;
      color: #fff;
      line-height: 45px;
      text-align: center; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form .control-icon input[type="tel"],
  .ccm-page .dynamic-form-container.callback-form .dynamic-form .control-icon input[type="text"] {
    padding-left: 70px; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form .control-icon textarea {
    height: 150px;
    padding-left: 70px; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form .control-icon.question-control label {
    height: 150px; }
    .ccm-page .dynamic-form-container.callback-form .dynamic-form .control-icon.question-control label::after {
      content: '\f0e5'; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form .control-icon.name-control label::after {
    content: '\f007'; }
  .ccm-page .dynamic-form-container.callback-form .dynamic-form .control-icon.phone-control label::after {
    content: '\f095'; }

.ccm-notification-help-launcher {
  display: none; }

.page-not-found {
  text-align: center;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  display: block; }
  .page-not-found h1 {
    padding-top: 20px;
    width: 100%;
    text-align: center;
    color: #4084c5;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 36px; }

.ccm-page .header .searchbar.sticky,
.ccm-page .header .header-top .menu-row .bottom-row.sticky {
  position: fixed;
  z-index: 300;
  width: 100%;
  top: 50px;
  margin-top: 0px; }

.ccm-page .header .searchbar.sticky {
  top: 55px; }

.ccm-page .header .header-top .menu-row .top-row.sticky {
  position: fixed;
  z-index: 400;
  max-width: 50%;
  top: 0px;
  margin-top: 0px; }

.ccm-toolbar-visible .ccm-page .header .header-top .menu-row .top-row.sticky,
.ccm-toolbar-visible .ccm-page .header .header-top .menu-row .bottom-row.sticky {
  top: 50px; }

.ccm-toolbar-visible .ccm-page .header .searchbar {
  top: 203px; }
  @media only screen and (min-width: 960px) {
    .ccm-toolbar-visible .ccm-page .header .searchbar {
      top: 0px; } }
  .ccm-toolbar-visible .ccm-page .header .searchbar.sticky {
    top: 102px; }
